import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/Seo";

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title={"Case Study | Dam Chicken"} />
      <div className={"text-center w-full"}>
        <h1 className={"text-3xl md:text-4xl"}>Welcome!</h1>
        <Link
          paintDrip
          hex={"#03543f"}
          to="/study/intro"
          className="mt-4 inline-block text-xl bg-primary py-2 px-3 rounded hover:bg-primary-alt"
          style={{ color: "white", textDecoration: "none" }}
        >
          Read the Case Study
        </Link>
      </div>
    </Layout>
  );
};

export default IndexPage;
